<template>
    <div class="probability-analysis">
        <el-table
            :data="tableData"
            style="width: 100%">
            <el-table-column
                prop="H"
                label="">
            </el-table-column>
            <el-table-column
                prop="X1"
                label="一等奖">
            </el-table-column>
            <el-table-column
                prop="X2"
                label="二等奖">
            </el-table-column>
            <el-table-column
                prop="X3"
                label="三等奖">
            </el-table-column>
            <el-table-column
                prop="X4"
                label="四等奖">
            </el-table-column>
            <el-table-column
                prop="X5"
                label="五等奖">
            </el-table-column>
            <el-table-column
                prop="X6"
                label="六等奖">
            </el-table-column>
            <el-table-column
                prop="X7"
                label="七等奖">
            </el-table-column>
            <el-table-column
                prop="X8"
                label="八等奖">
            </el-table-column>
            <el-table-column
                prop="X9"
                label="九等奖">
            </el-table-column>
            <el-table-column
                prop="X0"
                label="无">
            </el-table-column>
        </el-table>
        <div>
            <p>综合中奖概率：<span>{{ sumP }}</span></p>
            <p>综合期望：<span>{{ sumE }}</span></p>
            <p>几何分布，期望中奖所需要的次数：{{ 1 / sumP }}</p>
            <p>几何分布，期望中奖所需要的次数方差：{{ (1 - sumP) / (sumP ** 2) }}</p>
        </div>
    </div>
</template>

<script>
    import {combinations} from "mathjs";

    export default {
        name: "ProbabilityAnalysis",
        computed: {
            sumP() {
                let P = this.tableData[0]
                if (P) {
                    let p = 0;
                    Object.keys(P).forEach(key => {
                        if (typeof P[key] === 'number' && key !== 'X0') {
                            p += P[key]
                        }
                    })
                    return p;
                }
                return 0
            },
            sumE() {
                let E = this.tableData[1]
                if (E) {
                    let e = 0;
                    Object.keys(E).forEach(key => {
                        if (typeof E[key] === 'number') {
                            e += E[key]
                        }
                    })
                    return e;
                }
                return 0
            }
        },
        data() {
            return {
                tableData: []
            }
        },
        methods: {
            getP(r, b = 0) {
                let r_s = 5 - r;
                let b_s = 2 - b;
                return (combinations(5, r) * combinations(30, r_s) * combinations(2, b) * combinations(10, b_s)) / (combinations(35, 5) * combinations(12, 2))
            }
        },
        created() {
            let P1 = this.getP(5, 2);
            let P2 = this.getP(5, 1);
            let P3 = this.getP(5, 0);
            let P4 = this.getP(4, 2);
            let P5 = this.getP(4, 1);
            let P6 = this.getP(3, 2);
            let P7 = this.getP(4, 0);
            let P8 = this.getP(3, 1) + this.getP(2, 2);
            let P9 = this.getP(3, 0) + this.getP(1, 2) + this.getP(2, 1) + this.getP(0, 2);

            let P0 = 1 - P1 - P2 - P3 - P4 - P5 - P6 - P7 - P8 - P9;
            let P = {
                H: 'P(X)',
                X1: P1,
                X2: P2,
                X3: P3,
                X4: P4,
                X5: P5,
                X6: P6,
                X7: P7,
                X8: P8,
                X9: P9,
                X0: P0,
            }
            this.tableData.push(P)

            let E = {
                H: 'E(X)',
                X1: P1 * 7999998,
                X2: P2 * 50000,
                X3: P3 * 10000,
                X4: P4 * 3000,
                X5: P5 * 300,
                X6: P6 * 200,
                X7: P7 * 100,
                X8: P8 * 15,
                X9: P9 * 5,
                X0: P0 * -2
            }
            this.tableData.push(E)
        }
    }
</script>

<style scoped>

</style>
